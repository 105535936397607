<template>
  <div class="app-box">
    <div class="UlBox">
      <div class="imgCorl">
        <img
          v-if="Withdrawaldata.payment == 'transfer'"
          src="https://img.menghn.com/wx.png"
          alt=""
        />
        <img
          v-else-if="Withdrawaldata.payment == 'paybank'"
          src="https://img.menghn.com/gs.png"
          alt=""
        />
      </div>
      <div class="topTitle">
        <p>{{ Withdrawaldata.bank }}</p>
        <p class="money">{{ Withdrawaldata.amount }}</p>
        <p>{{ Withdrawaldata.state_name }}</p>
      </div>
      <!-- end -->
      <div class="listUl">
        <div class="left">提现方式</div>
        <div class="right" v-if="Withdrawaldata.payment == 'transfer'">
          微信零钱
        </div>
        <div class="right" v-else-if="Withdrawaldata.payment == 'paybank'">
          {{ Withdrawaldata.bank }}({{
            Withdrawaldata.bank_num.substring(
              Withdrawaldata.bank_num.length - 4
            )
          }})
        </div>
      </div>
      <div class="listUl">
        <div class="left">提现进度</div>
        <div class="right">
          <van-steps direction="vertical" :active="state" active-color="#38f">
            <template v-if="normalType">
              <van-step>
                <p class="pTitle">
                  待审核<span v-if="Withdrawaldata.add_time">{{
                    Withdrawaldata.add_time | dateString("MM-DD HH:mm")
                  }}</span>
                </p>
              </van-step>
              <van-step>
                <p class="pTitle">
                  发放中<span v-if="Withdrawaldata.audit_time">{{
                    Withdrawaldata.audit_time | dateString("MM-DD HH:mm")
                  }}</span>
                </p>
              </van-step>
              <van-step>
                <p class="pTitle">
                  已发放<span v-if="Withdrawaldata.payment_date">{{
                    Withdrawaldata.payment_date | dateString("MM-DD HH:mm")
                  }}</span>
                </p>
              </van-step>
            </template>
            <template v-else-if="!normalType">
              <van-step>
                <p class="pTitle">
                  待审核<span>{{
                    Withdrawaldata.add_time | dateString("MM-DD HH:mm")
                  }}</span>
                </p>
              </van-step>
              <van-step>
                <p class="pTitle">{{ Withdrawaldata.state_name }}</p>
              </van-step>
            </template>
          </van-steps>
        </div>
      </div>
      <div class="listUl">
        <div class="left">服务费</div>
        <div class="right">￥{{ Withdrawaldata.fee }}</div>
      </div>
      <div class="listUl">
        <div class="left">提现单号</div>
        <div class="right">{{ Withdrawaldata.order_sn }}</div>
      </div>
    </div>
    <p class="fontpeo">
      <span style="color: red">*</span
      >申请提现历史收益的时间为每月1-15号，既本月收益需下月申请
      提现，提现到账时间为每月20号到账，如遇节假日则顺延。且提现
      金额不能大于账户余额。
    </p>
    <div
      class="bottomBpoe"
      v-if="state == 0 || (state == 1 && normalType == true)"
    >
      <van-button type="info" block @click="revokeClick">撤销</van-button>
    </div>
    <div class="bottomBpoe" v-else>
      <van-button type="info" block @click="$router.go(-1)">完成</van-button>
    </div>
    <p class="bbfont">
      如遇问题，请联系客服:<a href="tel:400-861-8683">400-861-8683</a>
    </p>
      <Loding ref="loding" @load="load"></Loding>
  </div>
</template>

<script>
import { withdrawalDetail, cancelWithdrawal } from "@/request/api";
import moment from "moment";
import { Toast, Dialog } from "vant";
import Loding from "@/components/Loding/Loding"
export default {
  components:{
    Loding
  },
  data() {
    return {
      Withdrawaldata: {},
      state: "",
      normalType: "", //正常流程
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    load(){
      this.$refs.loding.loding = true
      this.$refs.loding.loderror = false
      this.getData();
    },
    getData() {
      let data = {
        id: this.$route.query.id,
      };
      withdrawalDetail(data).then((res) => {
        this.$refs.loding.loding = false;
        // console.log(this.$refs.loding.loding)
        this.Withdrawaldata = res.data;
        // 非正常流程
        if (res.data.state == 1 || res.data.state == 2 || res.data.state == 5) {
          this.normalType = true; //显示正常流程dom
          if (res.data.state == 1) {
            this.state = 0;
          } else if (res.data.state == 2) {
            this.state = 1;
          } else if (res.data.state == 5) {
            this.state = 2;
          }
        } else {
          this.state = 1;
          this.normalType = false; //显示非正常流程dom
        }
        
      }).catch(error=>{
        this.$refs.loding.loderror = true
        this.$refs.loding.loding = false
      })
    },
    // 撤销
    revokeClick() {
      Dialog.confirm({
        title: "提示",
        message: "确认撤销吗？",
      })
        .then(() => {
          let data = {
            id: this.$route.query.id,
          };
          cancelWithdrawal(data).then((res) => {
            if (res.code == 1002) {
              Toast.fail(res.data);
              return;
            }
            Toast.success(res.message);
            this.getData();
          });
        })
        .catch(() => {
          // on cancel
        });
    },
  },
  // 过滤器
  filters: {
    dateString(value, formate) {
      return moment(value).format(formate || "MMMM-YY-DD HH:mm:ss");
    },
  },
};
</script>

<style scoped>
.app-box {
  min-height: 100vh;
  background-color: #efeff4;
  box-sizing: border-box;
  overflow: hidden;
}
.UlBox {
  width: 95%;
  margin: auto;
  margin-top: 30px;
  margin-bottom: 10px;
  padding: 10px;
  box-sizing: border-box;
  background: #fff;
  border-radius: 10px;
  position: relative;
}
.UlBox .imgCorl {
  width: 40px;
  height: 40px;
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translate(-50%, 0);
  background: #fff;
  padding: 5px;
  border-radius: 50%;
  text-align: center;
}
.UlBox .imgCorl img {
  width: 90%;
  margin: auto;
}
.topTitle {
  text-align: center;
  margin-top: 25px;
  font-size: 18px;
}
.topTitle p {
  line-height: 30px;
}
.topTitle .money {
  font-weight: bold;
  font-size: 20px;
}
.listUl {
  display: flex;
}
.listUl {
  flex: 1;
  margin-top: 15px;
}
.listUl .left {
  flex: 1;
  color: #666c85ff;
}
.listUl .right {
  flex: 1;
  text-align: right;
}
.fontpeo {
  font-size: 15px;
  color: #999dadff;
  padding: 0 10px;
  box-sizing: border-box;
}
.bottomBpoe {
  padding: 20px 80px;
  box-sizing: border-box;
}
.bottomBpoe ::v-deep .van-button--block {
  border-radius: 10px;
}
.bbfont {
  font-size: 15px;
  padding: 0 10px;
  box-sizing: border-box;
  text-align: center;
}
::v-deep .van-icon-checked::before {
  font-size: 0.5rem;
}
::v-deep .van-step__circle {
  width: 0.25rem;
  height: 0.25rem;
  margin-left: 1px;
}
.pTitle {
  font-weight: bold;
}
.pTitle span {
  margin-left: 0.2rem;
  font-weight: 100;
}
</style>